import React, { Component } from "react"

const ServiceSection = () => {
  return (
    <section id="drain-page-img">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p className="call-link">
              <a href="#">Call Today! (702) 213-9574</a>
            </p>
          </div>
          <div className="col-md-4">
            <div className="img-one img-responsive text-center img-thumbnail"></div>
          </div>
          <div className="col-md-8 pt-3 list-styling">
            <ul>
              <li>Sinks Stoppages</li>
              <li>Clogged Toilets</li>
              <li>Sewers</li>
              <li>Video Camera Inspection</li>
              <li>All work guaranteed</li>
              <li>Fast response</li>
              <li>Discounts to Seniors and Military</li>
              <li>Manufactured Homes</li>
              <li>Phones answered 24/7</li>
              <li>100% Satisfaction Guarantee</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceSection
