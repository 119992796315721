import React from "react"

const Header3 = () => {
  return (
    <header id="drain-head">
      <div className="container">
        <div className="row">
          <div className="col-md-6 text-center">
            <img
              src={require("../../../images/drain/logo.png")}
              alt="title-img"
              className="img-fluid"
            />
          </div>

          <div className="col-md-6 text-center">
            <p className="head-p1">24/7 Emergency Service</p>
            <p className="head-p2">
              <i className="fa fa-phone-alt"></i>
              <a href="tel: +17022139574">(702) 213-9574</a>
            </p>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header3
