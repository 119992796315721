import React, { Component } from "react"
import DrainForm from "./Form/index"

const HeroSection = () => {
  return (
    <section id="drain-cnt">
      <div className="container">
        <div className="row">
          <div className="col-md-6 info-1">
            <p className="first-p">HAWTHORNE PLUMBING SERVICES</p>
            <h1>Las Vegas Clogged Drain Cleaning Service</h1>
            <p className="second-p">
              Trying to find the most dependable, affordable, and all around
              best drain cleaning and rooter services in Las Vegas, NV? Don’t
              look any further than Hawthorne Plumbing, Heating & Cooling. We’re
              ready and able to solve any plumbing problem that comes your way.
            </p>
          </div>

          <div className="col-md-6 info-2">
            <p className="third-p">Schedule Your Appointment Today!</p>
            <DrainForm />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroSection