import React, { Component } from "react";
import { Formik, Field, Form } from "formik";
import { Link, navigate, navigateTo } from "gatsby";

import * as Yup from "yup";

const ContactSchema = Yup.object().shape({
  fname: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First Name is Required"),
  lname: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Last Name is Required"),
  phone: Yup.string().required("Please Enter Your Phone Number"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is Required"),
  address: Yup.string().required("Please Enter Your Address"),
  city: Yup.string().required("Please Enter Your City"),
  postalcode: Yup.string().required("Please Enter Your Postal Code")
});

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

class DrainForm extends Component {
  handleSubmit = values => {
    // alert(JSON.stringify(values))
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "Drain Contact Form", values })
    })
      .then(() => {
        console.log("Your message submitted Successfully!");
        navigate("drain-cleaning-thank-you");
      })

      .catch(error => alert(error));

    console.log("submitted values :::::>", values);
  };

  render() {
    return (
      <Formik
        initialValues={{
          "bot-field": "",
          "form-name": "drain-cleaning-page-form",
          fname: "",
          lname: "",
          email: "",
          phone: "",
          address: "",
          city: "",
          postalcode: ""
        }}
        validationSchema={ContactSchema}
        onSubmit={this.handleSubmit}
      >
        {({
          errors,
          touched,
          dirty,
          isSubmitting,
          values,
          handleChange,
          handleBlur
        }) => (
          <Form
            name="contact"
            method="post"
            id="drain-form"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input
              type="hidden"
              name="form-name"
              value="contact"
            />

            <div className="form-row">
              <div className=" form-group col-md-6">
                <Field
                  type="text"
                  className={
                    errors.fname && touched.fname
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  name="fname"
                  placeholder="First Name "
                />
                {errors.fname && touched.fname ? (
                  <div className="invalid-feedback">{errors.fname}</div>
                ) : null}
              </div>
              <div className="form-group col-md-6">
                <Field
                  type="text"
                  className={
                    errors.lname && touched.lname
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  name="lname"
                  placeholder="Last Name "
                />
                {errors.lname && touched.lname ? (
                  <div className="invalid-feedback">{errors.lname}</div>
                ) : null}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <Field
                  type="email"
                  className={
                    errors.email && touched.email
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  name="email"
                  placeholder="Email  "
                />
                {errors.email && touched.email ? (
                  <div className="invalid-feedback">{errors.email}</div>
                ) : null}
              </div>

              <div className="form-group col-md-6">
                <Field
                  type="text"
                  className={
                    errors.phone && touched.phone
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  name="phone"
                  placeholder="Phone Number"
                />
                {errors.phone && touched.phone ? (
                  <div className="invalid-feedback">{errors.phone}</div>
                ) : null}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <Field
                  type="text"
                  className={
                    errors.address && touched.address
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  name="address"
                  placeholder="Street Address"
                />
                {errors.address && touched.address ? (
                  <div className="invalid-feedback">{errors.address}</div>
                ) : null}
              </div>

              <div className="form-group col-md-6">
                <Field
                  type="text"
                  className={
                    errors.city && touched.city
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  name="city"
                  placeholder="City"
                />
                {errors.city && touched.city ? (
                  <div className="invalid-feedback">{errors.city}</div>
                ) : null}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <select
                  type="text"
                  className="form-control"
                  name="usstate"
                  value={values.usstate}
                  placeholder="State"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option vlaue="" label="State" />
                  <option vlaue="Alabama" label="Alabama" />
                  <option vlaue="Alaska" label="Alaska" />
                  <option vlaue="Arizona" label="Arizona" />
                  <option vlaue="Arkansas" label="Arkansas" />
                  <option vlaue="California" label="California" />
                  <option vlaue="Colorado" label="Colorado" />
                  <option vlaue="Connecticut" label="Connecticut" />
                  <option vlaue="Delaware" label="Delaware" />
                  <option
                    vlaue="District od Columbia"
                    label="District od Columbia"
                  />
                  <option vlaue="Florida" label="Florida" />
                  <option vlaue="Georgia" label="Georgia" />
                  <option vlaue="Hawaii" label="Hawaii" />
                  <option vlaue="Idaho" label="Idaho" />
                  <option vlaue="Illinois" label="Illinois" />
                  <option vlaue="Iowa" label="Iowa" />
                  <option vlaue="Kansas" label="Kansas" />
                  <option vlaue="Kentucky" label="Kentucky" />
                  <option vlaue="Louisiana" label="Louisiana" />
                  <option vlaue="Maine" label="Maine" />
                  <option vlaue="Maryland" label="Maryland" />
                  <option vlaue="Massachusetts" label="Massachusetts" />
                  <option vlaue="Michigan" label="Michigan" />
                  <option vlaue="Minnesota" label="Minnesota" />
                  <option vlaue="Mississippi" label="Mississippi" />
                  <option vlaue="Missouri" label="Missouri" />
                  <option vlaue="Montana" label="Montana" />
                  <option vlaue="Nebraska" label="Nebraska" />
                  <option vlaue="New Hampshire" label="New Hampshire" />
                  <option vlaue="New Jersey" label="New Jersey" />
                  <option vlaue="New Mexico" label="New Mexico" />
                  <option vlaue="New York" label="New York" />
                  <option vlaue="North Carolina " label="North Carolina " />
                  <option vlaue="North Dakota" label="North Dakota" />
                  <option vlaue="Ohio" label="Ohio" />
                  <option vlaue="Oklahoma" label="Oklahoma" />
                  <option vlaue="Oregon" label="Oregon" />
                  <option vlaue="Rhode Island" label="Rhode Island" />
                  <option vlaue="South Carolina" label="South Carolina" />
                  <option vlaue="South Dakota" label="South Dakota" />
                  <option vlaue="Tennessee" label="Tennessee" />
                  <option vlaue="Texas" label="Texas" />
                  <option vlaue="Utah" label="Utah" />
                  <option vlaue="Vermont" label="Vermont" />
                  <option vlaue="Virginia" label="Virginia" />
                  <option vlaue="Washington" label="Washington" />
                  <option vlaue="West Virginia" label="West Virginia" />
                  <option vlaue="Wisconsin" label="Wisconsin" />
                  <option vlaue="New Option" label="New Option" />
                  <option vlaue="Wyoming" label="Wyoming" />
                </select>
              </div>
              <div className="form-group col-md-6">
                <Field
                  type="text"
                  className={
                    errors.postalcode && touched.postalcode
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  name="postalcode"
                  placeholder="Postal Code"
                />
                {errors.postalcode && touched.postalcode ? (
                  <div className="invalid-feedback">{errors.postalcode}</div>
                ) : null}
              </div>
            </div>
            <div className="sub-btn">
              <button
                type="submit"
                className="btn btn-lg"
                disabled={isSubmitting}
              >
                GET IN TOUCH
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default DrainForm;
