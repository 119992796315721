import React from "react"
import HeroSection from "./heroSection"
import ServiceSection from "./serviceSection"
import QuoteSection from "./quoteSection"

const DrainPage = () => {
  return (
    <React.Fragment>
      <HeroSection />
      <ServiceSection />
      <QuoteSection />
    </React.Fragment>
  )
}

export default DrainPage