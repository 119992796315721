import React from "react"
import "./index.css"

const Footer3 = () => {
  return (
    <footer id="drain-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p className="p1 text-center">
              © 2019 Hawthorne Plumbing, Heating. & Cooling | Privacy Policy
            </p>
            <p className="p2 text-center">
              NV CONT LIC :#0055673 C1 | #0070845 C21 $50,000 limit
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer3
