import React from "react"

const QuoteSection = () => {
  return (
    <section id="drain-quote">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p className="text-center">
              Our Team of Water Heater Specialists is Available 24/7 to Handle
              Your Water Heater Needs!
            </p>
          </div>
        </div>
        <div className="col-md-12 text-center">
          <iframe
            name="bfiframe"
            src="https://birdeye.com/widget/render.php?bid=152786008981990&amp;wid=6&amp;ver=4&amp;update=0"
            id="bfpublish"
            width="140"
            height="140"
            scrolling="no"
            frameBorder="0"
            className="rating-iframe text-center img-responsive"
          ></iframe>
          <br />
          <img src={require("../../images/drain/img1.png")} alt="images" />
        </div>
      </div>
    </section>
  )
}

export default QuoteSection
