import React, { Fragment } from "react";
import "./layout-3.css";
import "bootstrap/dist/css/bootstrap.css"
import HeaderThree from "../../Headers/Header-3/index";
import FooterThree from "../../Footer/Footer-3/index";

const LayoutThree = ({ children }) => {
  return (
    <Fragment>
      <HeaderThree />
      <main>{children}</main>
      <FooterThree />



    </Fragment>
      
     
   
  );
};

export default LayoutThree;
