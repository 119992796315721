import React from "react"
import LayoutThree from "../components/Layout/Layout-3/layout-3"
import SEO from "../components/seo"
import DrainPage from "../components/drain/index"


const DrainCleaning = () => (
  <LayoutThree>
    <SEO title="drain cleaning" />
    <DrainPage />
  </LayoutThree>
)

 export default DrainCleaning